import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'

import Obfuscate from 'react-obfuscate'

const TextStyle = styled.div`
  font-family: Neue Thin;
  letter-spacing: 0.13em;
  text-align: center;
  font-size: 88%;
`

const Bold = styled.span`
  font-family: Neue Roman;
`

const MainText = () =>
  <>
    <p>WE RISE BY LIFTING OTHERS</p>

    <p>We are a <Bold>new fresh consulting agency</Bold> based in Brussels with a deep knowledge and background in <Bold>business development, branding, marketing, on- and offline communication and digital strategy</Bold>.<br />
      We accompany and help companies in their general growth as well as their more specific needs in markets of niche brands, luxury, beauty, food, services,… We believe in a collaborative way of working to share competences and know-how to make all our missions shine and fly high…</p>
  </>

const Link = styled.a`
  text-decoration: none;
  color: black;
`


const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
  <TextStyle>
    <MainText />
    <Link as={Obfuscate} email="meetus@starsandbirds.com"
      headers={{
        subject: `let's meet`,
      }}
    />
  </TextStyle>
  </Layout>
)

export default IndexPage
